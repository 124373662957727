/* eslint-disable import/no-cycle */
import axios from 'axios'
import humps from 'humps'
import store from '@/store'
// eslint-disable-next-line import/no-cycle
import { getAccessToken } from '../utils/auth'

class HttpRequest {
  constructor (url = process.env.VUE_APP_API_URL || 'https://crmdemoapi.tubtim.in.th') {
    this.axiosInstance = axios.create({
      baseURL: url,
      timeout: 120000,
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      }
    })

    this.axiosInstance.interceptors.request.use((config) => {
      store.dispatch('activity/increaseLoading')
      return {
        ...config,
        data: config.data ? humps.decamelizeKeys(config.data) : config.data,
        params: config.params ? humps.decamelizeKeys(config.params) : config.params,
        headers: {
          Authorization: `Bearer ${ getAccessToken()}`
        }
      }
    }, (error) => {
      store.dispatch('activity/decreaseLoading')
      return Promise.reject(error)
    })

    this.axiosInstance.interceptors.response.use(
      (response) => {
        store.dispatch('activity/decreaseLoading')
        return humps.camelizeKeys(response.data)
      },
      (error) => {
        store.dispatch('activity/decreaseLoading')
        const newError = new Error(error)
        if (error?.response) {
          return Promise.reject(error.response.data)
        }
        const errorFail = {
          code: 503,
          message: newError.message
        }
        throw errorFail
      }
    )
  }

  setHeader ({ key, value }) {
    if (key && value) {
      this.axiosInstance.defaults.headers.common[key] = value
    }
  }

  get (endpoint, data, config) {
    return this.axiosInstance.get(endpoint, { params: data, ...config })
  }

  post (endpoint, data, config) {
    return this.axiosInstance.post(endpoint, data, config)
  }

  put (endpoint, data, config) {
    return this.axiosInstance.put(endpoint, data, config)
  }

  patch (endpoint, data, config) {
    return this.axiosInstance.patch(endpoint, data, config)
  }

  delete (endpoint, data, config) {
    return this.axiosInstance.delete(endpoint, { params: data, ...config })
  }
}

export default HttpRequest
